<template>
  <div class="agent-landing">
    <agent-hero @start="applyAgent"/>
    <div class="container">
      <item-section title="iPastBook介紹">
        <product :show-title="false">
          <div class="item-desc mb-1">
            <p>iPastBook一鍵導入FB，IG，列印成書，保存回憶的網站。</p>
            <p>現已覆蓋臺灣、香港、澳門、馬來西亞、美國等國家和地區。</p>
            <p>廣受辣媽、旅行者、 攝影愛好者等人群的喜愛。我們幫助愛生活、愛記錄的你留下時光裏那些美好的回憶。</p>
            <p>這一次，我們正式邀請你成為“iPastBook合夥人”。與我們一起將iPastBook分享給更多人。</p>
          </div>
        </product>
      </item-section>
      <item-section title="我们的优势">
        <div class="row">
          <div class="col-xs-4">
            <img src="../../../static/images/agent-landing/edit.png" class="item-icon" alt="messenger">
            <h5 class="item-title">
              低門檻，無需客服
            </h5>
            <div class="item-desc">
              iPastBook提供了合夥人的低门槛加盟方式，无需您提供客服，一切由iPastBook官方提供
            </div>
          </div>
          <div class="col-xs-4">
            <img src="../../../static/images/agent-landing/contact.png" class="item-icon" alt="messenger">
            <h5 class="item-title">
              專人對接，大量素材
            </h5>
            <div class="item-desc">
              提供專人一對一對接，並提供大量的宣傳素材供您使用
            </div>
          </div>
          <div class="col-xs-4">
            <img src="../../../static/images/agent-landing/cash.png" class="item-icon" alt="messenger">
            <h5 class="item-title">
              階梯等級，及時提現
            </h5>
            <div class="item-desc">
              根據您的銷售業績，自動提升提現比例，通過PayPal快速提現
            </div>
          </div>
        </div>
      </item-section>
      <item-section title="合夥人介紹">
        <div class="row">
          <div class="col-xs-6 col-sm-4 mt-1" v-for="(role, index) in roleList" :key="index">
            <img :src="role.icon" class="item-icon" alt="messenger">
            <h5 class="item-title">
              {{role.title}}
            </h5>
            <div class="item-desc fixed">
              {{role.desc}}
            </div>
          </div>
        </div>
      </item-section>
      <item-section title="獎勵機制">
        <div class="agent-reword">
          <div class="agent-reword-line clearfix">
            <div class="agent-reword-item header">粉絲下單總金額</div>
            <div class="agent-reword-item header">合夥人可提現粉絲下單總金額比例</div>
          </div>
          <template v-for="(item, index) in rewordList">
            <div class="agent-reword-line clearfix" :key="index">
              <div class="agent-reword-item">{{item.symbol + item.money}}</div>
              <div class="agent-reword-item">提現{{item.rate}}</div>
            </div>
          </template>
        </div>
      </item-section>
      <item-section title="簡單三步 獲得現金獎勵">
        <step :title-show="false" />
      </item-section>
      <div class="text-center mt-1">
        <button class="btn default btn-lg" v-copy="invitationLink">複製鏈接，邀請粉絲</button>
        <button class="btn primary btn-lg" @click="applyAgent">我要申請合夥人</button>
      </div>
    </div>
  </div>
</template>

<script>
import AgentHero from '../../components/layout/AgentLandingHero'
import ItemSection from '../../components/layout/AgentIntroSection'
import Step from '../../components/layout/InvitationStep'
import { mapGetters, mapActions } from 'vuex'
import api from 'src/api'
import Product from '../Home/Product'
export default {
  name: '',
  components: { AgentHero, ItemSection, Step, Product },
  data: () => ({
    rewordList: []
  }),
  computed: {
    ...mapGetters(['locale', 'user']),
    invitationLink () {
      return `https://ipastbook.com?ref=${this.user.uid}`
    },
    roleList () {
      return [
        {
          icon: require('../../../static/images/agent-landing/avatar-1.png'),
          title: '媽媽',
          desc: '經常混跡於各大母嬰社區，有自己的親子交流圈子和媽媽群，有一群誌同道合的朋友們！'
        },
        {
          icon: require('../../../static/images/agent-landing/avatar-2.png'),
          title: '內容創作者',
          desc: '你有自媒體帳號平臺（粉絲頁、部落格、個人網站等），希望通過文字把時光的故事傳遞和分享！'
        },
        {
          icon: require('../../../static/images/agent-landing/avatar-5.png'),
          title: '攝影愛好者',
          desc: '擁有一家攝影機構，或者三五攝影好友，把最美時光留在快門裏，也留在紀念冊裏！'
        },
        {
          icon: require('../../../static/images/agent-landing/avatar-3.png'),
          title: '老師',
          desc: '願意將孩子們的快樂時光留在一本紀念冊上！'
        },
        {
          icon: require('../../../static/images/agent-landing/avatar-4.png'),
          title: '小店經營者',
          desc: '願意給用戶提供最special的個人客制化產品！'
        },
        {
          icon: require('../../../static/images/agent-landing/avatar-6.png'),
          title: '導遊',
          desc: '願意把那些旅遊的美景和時光躍然紙上！'
        }
      ]
    }
  },
  created () {
    this.fetchRewords()
  },
  methods: {
    ...mapActions(['setLoginShow']),
    applyAgent () {
      if (this.user.uid) {
        this.$router.push('/agent')
      } else {
        this.setLoginShow(true)
      }
    },
    fetchRewords () {
      api.fetchAgentRules(this.locale).then(data => {
        data = data.map(item => {
          item.rate = item.rate * 100 + '%'
          return item
        })
        this.rewordList = data
      })
    }
  }
}
</script>

<style type="text/scss" lang="scss">
$primary: #FFE2E3;
.agent-landing {
  padding-bottom: 2rem;
  .btn{
    border-radius: 10px;
    &.primary {
      box-shadow: 0 12px 20px 0 rgba(198,198,198,0.5);
    }
  }
  h2.item-title {
    font-size: 1.35rem;
  }
  .agent-reword {
    border: 1px solid $primary;
    border-bottom-color: transparent;
    &-line {
      border-bottom: 1px solid $primary;
    }
    &-item {
      width: 50%;
      float: left;
      padding: 12px 0;
      &.header {
        font-weight: 500;
      }
    }
    .agent-reword-item + .agent-reword-item {
      border-left: 1px solid $primary;
    }
  }
}
</style>
